@import url(https://fonts.googleapis.com/css2?family=Inconsolata:wght@300;400;500;700&display=swap);
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  src: local('Poppins SemiBold'),
    url(/static/media/Poppins-SemiBold.ac8d04b6.ttf) format('truetype');
}

@font-face {
  font-family: 'Poppins Light';
  font-style: normal;
  font-weight: 200;
  src: local('Poppins Light'),
    url(/static/media/Poppins-Light.7641a0f7.ttf) format('truetype');
}

/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-family: 'Poppins';
  vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  line-height: 1;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
/* Joshua Samaniego Updates */
html {
  width: 100vw;
  height: 100vh;
}
body {
  width: 100vw;
  height: 100vh;
  box-sizing: border-box;
  overflow: auto;
}

/* GLOBAL STYLES */
.disabled {
  opacity: 0.4;
  pointer-events: none;
}

.WalletConnect {
  min-width: 100px;
  max-width: -moz-fit-content;
  max-width: fit-content;
  width: -moz-fit-content;
  height: 38px;
  display: flex;
  align-items: center;
  padding: 0px 10px;
  border: 2px solid #fbc51b;
  color: #fbc51b;
  user-select: none;
  -webkit-user-select: none;
}
.WalletConnect:hover {
  cursor: pointer;
}
.WalletConnected {
  display: flex;
  justify-content: center;
  align-items: center;
  width: -moz-fit-content;
  width: fit-content;
  height: auto;
  font-weight: 300;
}
.Jazzicon {
  width: 24px;
  height: 24px;
  margin-right: 10px;
}

/* Media Queries */
@media (max-width: 400px) {
 
  .WalletConnected {
    flex-direction: column;
    width: 150px;
    text-align: center;
  }
  .WalletConnected > p {
    margin-top: 3px;
    margin-bottom: -2px;
  }
  .Jazzicon {
    margin-right: 0px;
    margin-top: -5px;
  }
}

@media screen and (max-width: 786px) {
  .WalletConnect {
    height: -moz-fit-content;
    height: fit-content;
    margin-bottom: -0px;
    padding: 10px;
  }

  .Jazzicon {
    height: 0;
  }
}
.Nav {
    width: 92%;
    height: 120px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    
    z-index: 1000;
}
.TellorLogo {
    width: 140px;
}

/* Media Queries */
@media screen and (max-width: 786px) {
    .Nav {
        flex-direction: row;
        justify-content: space-around;
        width: 100%;
        height: 60px;
        background-color: #1c1c1c;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 1000;
    }
    .TellorLogo {
        font-size: 40px;
        width: 50px;
    }
}
@media (max-width: 500px) {
    .Nav {
        justify-content: space-around;
    }
}
/* Media Queries */
@media screen and (max-width: 786px) {
  .Footer {
    justify-content: center;
    height: 200px;
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    background-color: #1c1c1c;
    width: 100vw;
  }
  .FooterText {
    margin-left: 0;
  }
  .FooterNav{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 4em;
    width: 90%;
  }
  
  a{
    color: white;
    text-decoration: none;
  }
  
  a:hover{
    color: #fbc51b;
  }
}

@media (min-width: 786px) {
  .Footer {
    width: 100%;
    height: 77px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #1c1c1c;
    margin-top: 80px;
  }
  .FooterText {
    color: #777777;
    margin-left: 28px;
  }
  
  .FooterNav{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-right: 28px;
    width: 20%;
  }
  
  a{
    color: white;
    text-decoration: none;
  }
  
  a:hover{
    color: #fbc51b;
  }
}
  
@media screen and (max-width: 786px) {
  .wrapper{
    margin-top: 60px;
    padding: 1em;
  }
  .title{
    font-family: 'Poppins';
    font-size: 32px;
    line-height: 60px;
    margin-bottom: 20px;

  }
  .subtitle{
    font-family: 'Poppins';
    font-size: 20px;
    line-height: 60px;
    margin-bottom: 20px;
    color: #fbc51b;
  }
  .subtitle2{
    font-family: 'Poppins';
    font-size: 20px;
    line-height: 60px;
    margin-bottom: 0px;
    width: 100%;
    text-align: left;
    color: #fbc51b;
  }
  .subtitle2w{
    font-family: 'Poppins';
    font-size: 15px;
    line-height: 50px;
    margin-bottom: 0px;
    width: 100%;
    text-align: left;
    color: #ffffff;
  }
  .subtitle2l{
    font-family: 'Poppins';
    font-size: 20px;
    line-height: 10px;
    margin-bottom: 20px;
    width: 100%;
    text-align: left;
    color: #fbc51b;
  }
  .mainText{
    font-size: 22px;
    line-height: 28px;
    font-weight: 300;
    margin-bottom: 30px;
  }
  .infoButtons {
    min-width: 200px;
    height: 48px;
    display: flex;
    text-align: center;
    align-items: center;
    font-size: larger;
    margin-right: 1em;
    padding: 0px 10px;
    border: 2px solid #fbc51b;
    background-color: #242424;
    cursor: pointer;
    color: #fbc51b;
    margin-top: 1em;
  }

  .infoButtons:hover {
    background-color: #fbc51b;
    color: #242424;
  }

  .HeroFundFeed{
    margin-top: 1em;
    border: 2px solid #242424;
    color: #242424;
    font-weight: 500;
    width: 50%;
    padding: 1em;
  }
}

@media (min-width: 786px) {
  .wrapper{
    width: 90%;
    margin-left: 10%;
    margin-top: 2em;
    grid-column: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 2em;
  }

  .title{
    font-family: 'Poppins';
    font-size: 40px;
    line-height: 100px;
    margin-bottom: -10px;
    width: 100%;
    text-align: left;
  }

  .subtitle{
    font-family: 'Poppins';
    font-size: 20px;
    line-height: 60px;
    margin-bottom: 20px;
    width: 100%;
    text-align: left;
    color: #fbc51b;
  }
  .subtitle2{
    font-family: 'Poppins';
    font-size: 20px;
    line-height: 60px;
    margin-bottom: 0px;
    width: 100%;
    text-align: left;
    color: #fbc51b;
  }
  .subtitle2w{
    font-family: 'Poppins Light';
    font-size: 17px;
    font-weight: 300;
    line-height: 10px;
    margin-top: 100px;
    padding: 0px 0px;
    margin-bottom: 0px;
    width: 100%;
    height: 48px;
    text-align: left;
    color: #ffffff;
  }
  .subtitle2l{
    font-family: 'Poppins';
    font-size: 20px;
    line-height: 30px;
    margin-bottom: 0px;
    width: 100%;
    text-align: left;
    color: #fbc51b;
  }


  .mainText{
    font-size: 18px;
    line-height: 28px;
    font-weight: 300;
    margin-bottom: 30px;
    color: #ffffff;
  }

  .mainText2{
    font-size: 18px;
    line-height: 28px;
    font-weight: 300;
    margin-bottom: -35px;
    color: #ffffff;
  }
  .mainText2 > a {
    font-size: 18px;
    line-height: 28px;
    font-weight: 300;
    margin-bottom: -40px;
    color: #fbc51b;
  }

  .infoContainer{
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: row;
  }

  .infoButtons {
    min-width: 200px;
    height: 48px;
    display: flex;
    text-align: center;
    align-items: center;
    font-size: larger;
    margin-right: 1em;
    padding: 0px 10px;
    border: 2px solid #fbc51b;
    background-color: #242424;
    cursor: pointer;
    color: #fbc51b;
  }

  .infoButtons:hover {
    background-color: #fbc51b;
    color: #242424;
  }
}

@media screen and (max-width: 786px) {
  .HeroInnerContainer{
    background-color: #1a1919;
    border: 1px solid #fbc51b8d;
    min-height: 100vh;
    max-width: 95vw;
    margin-left: 2.5vw;
    padding: 1em;
    box-sizing: border-box;
    font-size: medium;
    margin-bottom: 2em;
  }
  .HeroHeader {
    font-family: 'Poppins';
    margin-top: 1em;
    margin-bottom: 1em;
  }
  .HeroMainText {
    font-size: 22px;
    line-height: 28px;
    font-weight: 300;
    margin-bottom: 30px;
  }
  .HeroInfoBox {
    width: 100%;
    height: 124px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px solid #ffffff;
    margin: 44px 0px 30px 0px;
    -webkit-user-select: none;
            user-select: none;
  }
  .HeroInfoBoxConnected {
    width: 100%;
    height: 124px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border: 2px solid #ffffff;
    margin: 44px 0px 30px 0px;
    padding-left: 20px;
    -webkit-user-select: none;
            user-select: none;
  }

  

  input{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 95%;
    background-color: rgba(251,197, 27, 0.2);
    border-bottom: 2px solid #fbc51b;
    font-size: large;
    padding-left: 0.5em;
    color: white;
    margin-top: 0.5em;
    margin-bottom: 0.5em;
    height: 40px;
  }
  select{
    font-size: large;
    color: white;
    background-color: rgba(251,197, 27, 0.2);
    border-bottom: 2px solid #fbc51b;
    
  }
}

@media screen and (min-width: 786px) {
  .HeroInnerContainer {
    background-color: #222222;
    margin-left: 15%;
    border-radius: 25px;
    border: 2px solid #fbc51b3e;
    width: 70%;
    margin-top: 2em;
    grid-column: 2;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 2em;   
  }
  hr{
    max-width: 100%;
    margin-bottom: 1em;
  }
  .HeroHeader {
    font-family: 'Poppins';
    font-size: 40px;
    line-height: 60px;
    margin-bottom: -20px;
  }
  .HeroMainText {
    font-size: 18px;
    line-height: 28px;
    font-weight: 300;
    margin-bottom: 30px;
  }
  .HeroMainText > a {
    color: #fbc51b;
    text-decoration: none;
  }
  .mainText > a {
    color: #fbc51b;
    text-decoration: none;
  }
  .HeroDropdowns > label {
    font-size: 18px;
    line-height: 28px;
    font-weight: 300;
  }
  #assetInput {
    margin-right: 16px;
    margin-bottom: 30px;
    width: 70px;
  }
  .dropdown {
    width: 100px;
    height: 22px;
    font-family: 'Poppins Light';
    font-size: 18px;
    font-weight: 300;
    padding-left: 5px;
    padding-top: 2px;
    border: none;
    background-color: rgba(251,197, 27, 0.2);
    border-bottom: 2px solid #fbc51b;
    color: #ffffff;
    caret-color: #ffffff;
  }
  .dropdown::placeholder {
    color: #ffffff;
    opacity: 0.6;
  }
  .dropdown:focus,
  .dropdown:focus-visible,
  .dropdown:focus-within {
    outline-color: #fbc51b;
    outline-width: 1px;
  }
  /* */
  .HeroInfoBox {
    width: 268px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1em;
    padding-left: 0em;
    padding-right: 0em;
    border: 2px solid #222222;
    margin: 44px 0px 30px 0px;
    -webkit-user-select: none;
            user-select: none;
  }
  .HeroInfoBoxConnected {
    width: 418px;
    height: 124px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border: 2px solid #ffffff;
    margin: 44px 0px 30px 0px;
    padding-left: 20px;
    -webkit-user-select: none;
            user-select: none;
  }
  /* */
  .HeroSetParameters {
    width: 418px;
    margin-bottom: 30px;

    -webkit-user-select: none;

            user-select: none;
  }
  .HeroSetParameters > p {
    font-weight: 300;
    font-size: 18px;
  }
  .HeroParameterFeedNumberInputLarge {
    width: 50px;
    height: 25px;
    font-family: 'Poppins Light';
    font-size: 18px;
    font-weight: 300;
    padding: 0;
    padding-left: 5px;
    padding-right: 5px;
    margin-right: 2px;
    margin-bottom: 3px;
    border: none;
    background-color: rgba(251,197, 27, 0.2);
    border-bottom: 2px solid #fbc51b;
    color: #ffffff;
    caret-color: #ffffff;
  }
  .HeroParameterFeedNumberInputLarge:focus,
  .HeroParameterFeedNumberInputLarge:focus-visible,
  .HeroParameterFeedNumberInputLarge:focus-within {
    outline-color: #fbc51b;
    outline-width: 1px;
  }
  .HeroParameterNumberInput {
    width: 25px;
    height: 25px;
    font-family: 'Poppins Light';
    font-size: 18px;
    font-weight: 300;
    padding: 0;
    padding-left: 5px;
    margin-right: 2px;
    margin-bottom: 3px;
    border: none;
    background-color: rgba(251,197, 27, 0.2);
    border-bottom: 2px solid #fbc51b;
    color: #ffffff;
    caret-color: #ffffff;
  }
  .HeroParameterNumberInput::placeholder {
    color: #ffffff;
    opacity: 0.6;
  }
  .HeroParameterNumberInput:focus,
  .HeroParameterNumberInput:focus-visible,
  .HeroParameterNumberInput:focus-within {
    outline-color: #fbc51b;
    outline-width: 1px;
  }
  .HeroParameterDropdownInput {
    width: 90px;
    height: 27px;
    font-family: 'Poppins Light';
    font-size: 18px;
    font-weight: 300;
    border: none;
    margin-bottom: 5px;
    background-color: rgba(251,197, 27, 0.2);
    border-bottom: 2px solid #fbc51b;
    color: #ffffff;
    caret-color: #ffffff;
  }
  .HeroParameterDropdownInput::placeholder {
    color: #ffffff;
    opacity: 0.6;
  }
  .HeroParameterDropdownInput:focus,
  .HeroParameterDropdownInput:focus-visible,
  .HeroParameterDropdownInput:focus-within {
    outline-color: #fbc51b;
    outline-width: 1px;
  }
  
  .HeroParameterFeedNumberInputSmall {
    width: 25px;
    height: 25px;
    font-family: 'Poppins Light';
    font-size: 18px;
    font-weight: 300;
    padding: 0;
    padding-left: 5px;
    padding-right: 5px;
    margin-right: 2px;
    border: none;
    background-color: rgba(251,197, 27, 0.2);
    border-bottom: 2px solid #fbc51b;
    color: #ffffff;
    caret-color: #ffffff;
  }
  .HeroParameterFeedNumberInputSmall:focus,
  .HeroParameterFeedNumberInputSmall:focus-visible,
  .HeroParameterFeedNumberInputSmall:focus-within {
    outline-color: #fbc51b;
    outline-width: 1px;
  }
  .HeroParameterFeedNumberInputMedium {
    width: 40px;
    height: 25px;
    font-family: 'Poppins Light';
    font-size: 18px;
    font-weight: 300;
    padding: 0;
    padding-left: 5px;
    padding-right: 5px;
    margin-right: 2px;
    border: none;
    background-color: rgba(251,197, 27, 0.2);
    border-bottom: 2px solid #fbc51b;
    color: #ffffff;
    caret-color: #ffffff;
  }
  .HeroParameterFeedNumberInputMedium:focus,
  .HeroParameterFeedNumberInputMedium:focus-visible,
  .HeroParameterFeedNumberInputMedium:focus-within {
    outline-color: #fbc51b;
    outline-width: 1px;
  }
  /* */
  .HeroFundFeed {
    width: -moz-fit-content;
    width: fit-content;
    height: 38px;
    padding: 0px 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px solid #fbc51b;
    color: #fbc51b;
    -webkit-user-select: none;
            user-select: none;
  }
  .HeroFundFeed:hover {
    cursor: pointer;
  }
  
}

@media screen and (min-width: 1624px) {
  .HeroInnerContainer {
    background-color: #222222;
    margin-left: 15%;
    border-radius: 25px;
    width: 70%;
    margin-top: 2em;
    grid-column: 2;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 2em;
  } 

 
}
.InfoBoxConnected {
  display: flex;
  flex-direction: column;
  height: 100%;
  font-weight: 300;
  justify-content: space-evenly;
}

@media screen and (max-width: 786px) {
  .App{
    width: 100vw;
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-color: #262626;
    color: #ffffff;
    overflow-y: scroll;
  }
}

@media (min-width: 786px) {
  .App {
    width: 100%;
    min-height: 100vh;
    text-align: left;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-image: linear-gradient(45deg,  #1f1f1f, #242424, #363535);
    color: #ffffff;
    font-family: 'Poppins Light';
  }

  .HeroContainer {
    width: 92vw;
    height: 100%;
    min-height: 80vh;
    overflow: auto;
    display: flex;
    flex-direction: column;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
}
